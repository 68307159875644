import { render, staticRenderFns } from "./salesroom.vue?vue&type=template&id=5527f928&scoped=true"
import script from "./salesroom.vue?vue&type=script&lang=js"
export * from "./salesroom.vue?vue&type=script&lang=js"
import style0 from "./salesroom.vue?vue&type=style&index=0&id=5527f928&prod&lang=stylus&rel=stylesheet%2Fstylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5527f928",
  null
  
)

export default component.exports