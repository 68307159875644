<template>
  <div>
    <mt-button class="mb15">
      <el-button @click="onAddBtnClick" type="primary">新增</el-button>
      <el-button @click="onRefreshBtnClick">刷新</el-button>
    </mt-button>
    <el-table
      class="salesroom-table"
      ref="visitTable"
      :data="salesroomList"
      stripe
      :height="tableHeight"
      :maxHeight="tableHeight"
      style="width: 100%;">
      <el-table-column
        header-align="left"
        type="index"
        label="#"
        :index="formatterIndex"
        width="50">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        prop="name"
        label="名称">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        width="100"
        prop="chargePerson"
        label="管理员">
      </el-table-column>
      <el-table-column
        header-align="left"
        align="left"
        width="280"
        prop="address"
        label="地址">
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="门店照片">
        <template slot-scope="scope">
          <el-button @click="showImage(scope.row)" type="text">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="门店二维码">
        <template slot-scope="scope">
          <el-button @click="showErCode(scope.row)" type="text">查看</el-button>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="在售产品">
        <template slot-scope="scope">
          <template v-if="scope.row.saleTypes.length !== 0">
            <el-tag v-for="item in scope.row.saleTypes" :key="item.id" type="success">{{item.name}}</el-tag>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="关联屠宰场">
        <template slot-scope="scope">
          <template v-if="scope.row.slaughters.length !== 0">
            <el-tag v-for="item in scope.row.slaughters" :key="item.id" type="success">{{item.name}}</el-tag>
          </template>
          <template v-else>
            <div>-</div>
          </template>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        label="状态">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === SALESROOM_STATUS.DO_BUSINESS" type="success">营业</el-tag>
          <el-tag v-else type="info">歇业</el-tag>
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        width="100"
        label="操作">
        <template slot-scope="scope">
          <el-tooltip class="item" effect="dark" content="编辑" placement="top">
            <el-button @click.stop="onEditBtnClick(scope.row)" type="primary" icon="el-icon-edit" circle></el-button>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="删除" placement="top">
            <el-button @click.stop="onDeleteBtnClick(scope.row)" type="danger" icon="el-icon-delete" circle></el-button>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @current-change="onPageChange"
      layout="->, total, prev, pager, next"
      :page-size="pageSize"
      :current-page="pageIndex"
      :total="salesroomTotal"
      class="pagination">
    </el-pagination>
    <mt-image-preview
      :images="previewImages"
      :is-show-image-list="false"
      @dialog-close="showImageVisible = false"
      :dialog-image-visible="showImageVisible"></mt-image-preview>

    <el-dialog :title="model.id === 0 ? '新增门店' : '编辑门店'" :visible.sync="dialogVisible" width="50%">
      <el-form :rules="rules" ref="dataForm" :model="model" label-position="left" label-width="100px" class="data-form"
               style='width: 500px; margin:0 auto;'>
        <el-form-item label="名称" prop="name">
          <el-input v-model="model.name"></el-input>
        </el-form-item>
        <el-form-item label="管理员" prop="chargePerson">
          <el-input v-model="model.chargePerson"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="model.address"></el-input>
        </el-form-item>
        <el-form-item label="在售产品" required prop="saleTypeIds">
          <el-select multiple v-model="model.saleTypeIds">
            <el-option v-for="item in saleTypeList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关联屠宰场" required prop="slaughterhouseIds">
          <el-select multiple v-model="model.slaughterhouseIds">
            <el-option v-for="item in slaughterhouseList" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="照片" required prop="picture">
          <mt-upload-file ref="fileUpload" :limit="1" @upload-success="uploadSuccess" @delete="deleteFile"></mt-upload-file>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="editSalesroom">确认</el-button>
      </div>
    </el-dialog>
    <el-dialog title="门店二维码" :visible.sync="dialogErCodeVisible" width="30%">
      <div class="sale-type-select">
        <div class="sale-type-label">在售产品类别</div>
        <el-select v-model="currentSelectSaleType" @change="onSaleTypeChange">
          <el-option v-for="item in currentSalesroomSaleType" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </div>
      <vue-qr width="300" :logoMargin="3" :correctLevel="3" :logoSrc="erCodeLogo" :text="erCodeUrl" :callback="erCodeComplete"></vue-qr>
      <div slot="footer" class="dialog-footer">
        <a style="margin-right: 15px" :href="erCodeDataUrl" download="二维码">
          <el-button>下载</el-button>
        </a>
        <el-button type="primary" v-clipboard:copy="erCodeUrl" v-clipboard:success="copySuccess" v-clipboard:error="copyError">复制链接</el-button>
        <el-button type="primary" @click="dialogErCodeVisible = false">确认</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script type="text/ecmascript-6">
  import MtButton from '../../components/mt-button'
  import MtImagePreview from '../../components/mt-image-preview'
  import MtUploadFile from '../../components/mt-upload-file'
  import VueQr from 'vue-qr'
  import {tableMixin} from '../../mixins/table'
  import {apiGetSalesroomList, apiEditSalesroom, apiDeleteById, apiGetErCode} from '../../api/salesroom'
  import {apiGetSlaughterhouseList} from '../../api/slaughterhouse'
  import {apiGetSaleTypeList} from '../../api/saleType'
  import {SALESROOM_STATUS} from '../../utils/constants'

  export default {
    name: 'TraceSalesroom',
    mixins: [tableMixin],
    components: {MtButton, MtImagePreview, MtUploadFile, VueQr},
    data() {
      let validateIsSelect = (rule, value, callback) => {
        if (value.length === 0) {
          callback(new Error('请选择'))
        } else {
          callback()
        }
      }
      return {
        salesroomList: [],
        salesroomTotal: 0,
        dialogVisible: false,
        dialogErCodeVisible: false,
        model: {
          id: 0,
          name: '',
          address: '',
          picture: '',
          chargePerson: '',
          slaughterhouseIds: [],
          saleTypeIds: []
        },
        rules: {
          name: [{required: true, message: '不能为空', trigger: 'blur'}],
          chargePerson: [{required: true, message: '不能为空', trigger: 'blur'}],
          address: [{required: true, message: '不能为空', trigger: 'blur'}],
          picture: [{required: true, message: '不能为空', trigger: 'change'}],
          slaughterhouseIds: [{validator: validateIsSelect, trigger: 'change'}],
          saleTypeIds: [{validator: validateIsSelect, trigger: 'change'}]
        },
        SALESROOM_STATUS,
        previewImages: [],
        showImageVisible: false,
        erCodeUrl: '',
        erCodeDataUrl: '',
        erCodeLogo: require('../../assets/images/logo.png'),
        slaughterhouseList: [],
        saleTypeList: [],
        currentSalesroomSaleType: [],
        currentSelectSaleType: 1
      }
    },
    created() {
      this._getSlaughterhouseList()
      this._getSaleTypeList()
    },
    activated() {
      this._getSalesroomList()
    },
    methods: {
      onAddBtnClick() {
        this.dialogVisible = true
        this._clearModel()
        this._resetModel()
      },
      onEditBtnClick(row) {
        this._clearModel()
        this.dialogVisible = true
        this.model = Object.assign({}, this.model, row)
        let slaughterhouseIds = []
        if (row.slaughters.length !== 0) {
          row.slaughters.forEach(item => {
            slaughterhouseIds.push(item.id)
          })
        }
        this.model.slaughterhouseIds = slaughterhouseIds
        let saleTypeIds = []
        if (row.saleTypes.length !== 0) {
          row.saleTypes.forEach(item => {
            saleTypeIds.push(item.id)
          })
        }
        this.model.saleTypeIds = saleTypeIds
        this.$nextTick(() => {
          this.$refs['fileUpload'].setFile(row.picture, row.pictureURL)
        })
      },
      onRefreshBtnClick() {
        this._getSalesroomList()
      },
      editSalesroom() {
        this.$refs['dataForm'].validate((valid) => {
          if (valid) {
            let slaughters = []
            this.model.slaughterhouseIds.forEach(item => {
              slaughters.push({
                id: item
              })
            })
            let saleTypes = []
            this.model.saleTypeIds.forEach(item => {
              saleTypes.push({
                id: item
              })
            })
            this.model.slaughters = slaughters
            this.model.saleTypes = saleTypes
            apiEditSalesroom(this.model).then(({data}) => {
              this.pageIndex = 1
              this._getSalesroomList()
              this.dialogVisible = false
              this.$message({
                type: 'success',
                message: this.model.id === 0 ? '添加成功' : '修改成功'
              })
              this._resetModel()
            })
          }
        })
      },
      uploadSuccess(fileName) {
        this.model.picture = fileName
        this.$refs['dataForm'].clearValidate('picture')
      },
      deleteFile(fileName) {
        this.model.picture = ''
      },
      onDeleteBtnClick(row) {
        this.$confirm('是否确认删除该门店？', '提示', {
          confirmButtonText: '确认',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          apiDeleteById(row.id).then(data => {
            this._getSalesroomList()
            this.$message({
              type: 'success',
              message: '删除成功'
            })
          })
        })
      },
      onSaleTypeChange(val) {
        apiGetErCode(this.model.id, val).then(({data}) => {
          this.erCodeUrl = data
        })
      },
      showErCode(row) {
        const saleTypes = row.saleTypes
        if (!saleTypes || saleTypes.length === 0) {
          this.$message({
            type: 'error',
            message: '请先将门店在售产品维护'
          })
          return
        }
        this.model = Object.assign({}, this.model, row)
        this.dialogErCodeVisible = true
        this.currentSalesroomSaleType = saleTypes
        this.currentSelectSaleType = saleTypes[0].id
        apiGetErCode(row.id, saleTypes[0].id).then(({data}) => {
          this.erCodeUrl = data
        })
      },
      erCodeComplete(dataUrl, id) {
        this.erCodeDataUrl = dataUrl
      },
      showImage(row) {
        this.previewImages = row.pictureURL
        this.showImageVisible = true
      },
      copySuccess() {
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      },
      copyError() {
        this.$alert(`请手动复制链接: ${this.erCodeUrl}`, '复制失败', {
          confirmButtonText: '确认',
          showClose: false,
          type: 'error'
        }).then(() => {})
      },
      onPageChange(val) {
        this.pageIndex = val
        this._getSalesroomList(val)
      },
      _clearModel() {
        this.$nextTick(() => {
          this.$refs['dataForm'].clearValidate()
          this.$refs['fileUpload'].clearFile()
        })
      },
      _resetModel() {
        this.model = {
          id: 0,
          name: '',
          address: '',
          picture: '',
          chargePerson: '',
          slaughterhouseIds: [],
          saleTypeIds: []
        }
      },
      _getSalesroomList(pageIndex = this.pageIndex) {
        apiGetSalesroomList(pageIndex).then(({data}) => {
          this.salesroomList = data.data
          this.salesroomTotal = data.total
        })
      },
      _getSlaughterhouseList() {
        apiGetSlaughterhouseList(1, 99999).then(({data}) => {
          this.slaughterhouseList = data.data
        })
      },
      _getSaleTypeList() {
        apiGetSaleTypeList(1, 99999).then(({data}) => {
          this.saleTypeList = data.data
        })
      }
    }
  }
</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>
  .data-form >>> .el-form-item__content
    text-align left
  .salesroom-table >>> .el-tag
    margin-right 2px
  .sale-type-select
    display flex
    align-items center
    justify-content center
    .sale-type-label
      margin-right 15px
      width 6em
</style>
