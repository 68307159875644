/**
 * Created by ZengFanhui on 2021/1/4 9:13
 */
import { doHttp } from '../utils/common'
import {HTTP_TYPE, PAGE_SIZE} from '../utils/constants'

/**
 * 获取门店列表
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetSalesroomList (pageIndex = 1, pageSize = PAGE_SIZE) {
  let params = {
    pageIndex,
    pageSize
  }
  return doHttp('/trace/salesroom/list.action', HTTP_TYPE.GET, params)
}

/**
 * 获取所有门店列表
 * @param pageIndex
 * @param pageSize
 * @returns {*}
 */
export function apiGetAllSalesroomList () {
  return doHttp('/trace/salesroom/getAll.action', HTTP_TYPE.GET, {})
}

/**
 * 编辑门店
 * @param params
 * @returns {*}
 */
export function apiEditSalesroom(params) {
  return doHttp('/trace/salesroom/edit.action', HTTP_TYPE.POST, params)
}

/**
 * 根据ID删除
 * @param id
 * @returns {*}
 */
export function apiDeleteById(id) {
  let params = {id}
  return doHttp('/trace/salesroom/delete.action', HTTP_TYPE.GET, params)
}

/**
 * 获取门店二维码地址
 * @param id
 * @param saleType
 * @returns {*}
 */
export function apiGetErCode(id, saleType) {
  let params = {id, saleType}
  return doHttp('/trace/salesroom/ercode.action', HTTP_TYPE.GET, params)
}
